<template>
  <div style="height: 100vh;">
    <v-card flat rounded="0">
      <v-container>
        <div class="d-flex">
          <v-btn @click="$router.push('/finance')" class="my-auto" icon>
            <v-icon>fal fa-arrow-left</v-icon>
          </v-btn>
        </div>
      </v-container>
    </v-card>
    <v-container fill-height>
      <div class="text-center">
        <v-icon size="60" color="error">far fa-frown</v-icon>
        <h1>Uh ohh!</h1>
        <div>
          Unfortunately, Your application did not meet the minimum required
          criteria for loan approval. Please read more about it
          <a href="">here</a>. If you believe there has been a mistake, reach
          out to us at
          <span class="font-weight-bold">support@ontrack.in</span>
        </div>
        <div class="my-10">
          <v-btn color="primary" large @click="goBack">
            Try Again
          </v-btn>
        </div>
      </div>
      <v-card>
        <v-card-title>
          What Should you do now ?
        </v-card-title>

        <v-card-subtitle>
          Most people have their own reasons for why they want to buy a
          motorcycle. You may also have a plenty of reasons to buy an amazing
          two-wheeler. With numerous bike loans available, it is easy for you to
          buy a motorcycle of your choice. Nevertheless, you would want to know
          if you are eligible for it.
        </v-card-subtitle>

        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header style="border:0;">
              Read more about eligibility criteria
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              What are the factors that determine an individual’s bike loan
              eligibility? Most banks are extremely careful, and they approve
              the bike loan only after ensuring the financial stability of the
              borrower. Moreover, evaluating an individual’s two wheeler loan
              eligibility depends on certain aspects the bank considers.
              However, some common ones that most lenders look for bike
              financing are:
              <ul>
                <li>
                  <strong>Age:</strong> You should be 21 years or above. If you
                  are between 18 and 21 years, you need a co-applicant. Your
                  maximum age at the end of the bike loan term should be 65
                  years or the age at the time of retirement, whichever comes
                  earlier.
                </li>
                <li>
                  <strong>Income: </strong>You should be employed and draw a
                  minimum annual salary, which differs from one bank to another
                  bank. For those who are self-employed, the minimum annual
                  income is again different. If you have a higher salary or
                  income, you will be eligible for a higher amount of bike loan.
                  However, the higher income will not guarantee a bike loan for
                  you.
                </li>
                <li>
                  <strong>Minimum work experience: </strong>Even though minimum
                  work experience differs from one bank to another bank, you
                  should have at least six months’ experience working in your
                  present employment.In the case of entrepreneurs or
                  self-employed individuals, they should have been running the
                  business for a minimum period of two years to qualify for a
                  bike loan eligibility.
                </li>
                <li>
                  <strong>Repayment capability: </strong>Your repayment capacity
                  is one of the important aspects a bank considers before
                  sanctioning a bike loan. In the process, it will assess your
                  repayment history, as well as the other loans you have now, to
                  ensure whether you have the wherewithal to pay. For that, your
                  bank reviews your income and figure out your debt service
                  coverage ratio. If it is less than 1, you have a fewer chance
                  to secure a two wheeler loan eligibility or you will get less
                  loan amount.
                </li>
                <li>
                  <strong>Credit score: </strong>You need to have a good credit
                  history to be considered for a motorcycle loan. This score is
                  a three-digit number, and it shows your creditworthiness. You
                  can avail a bike loan at a lower rate of interest if you have
                  a credit score of 750 and above.
                </li>
                <li>
                  <strong>Your residence: </strong>The place where you live is
                  also considered for bike financing. Living in a city gives you
                  a better chance of having your loan approved than living in a
                  countryside.
                </li>
                <li>
                  <strong>Your employment: </strong>A bank may also consider the
                  company you work for. This will help it know whether you have
                  a stable job with a regular income.
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <v-container>
        <div class="pa-2">
          <p class="font-italic caption text--disabled text-justify">
            <span class="text--disabled"
              ><strong>Disclaimer</strong><br
            /></span>
            The contents of this article / infographic / picture / video are
            meant solely for information purposes. The contents are generic in
            nature and for informational purposes only. It is not a substitute
            for specific advice in your own circumstances. The information is
            subject to updation, completion, revision, verification and
            amendment and the same may change materially. Ontrack Technologies
            shall not be responsible for any direct/indirect loss or liability
            incurred by the reader for taking any financial decisions based on
            the contents and information mentioned. Please consult your
            financial advisor before making any financial decision.
          </p>
        </div>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Result",
  methods: {
    goBack() {
      return this.$router.go(-4);
    },
  },
};
</script>

<style scoped></style>
